import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { themeColors } from "../../../settings";
import { ReactSVG } from "react-svg";
import { useHover } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

function CtaButton({ className, label, iconPath, onClick, iconClassName, url }) {
    const [active, setActive] = useState(false);
    return (
        <>
            {onClick ?
                <button
                    onClick={onClick}
                    className={clsx(
                        "flex items-center justify-center bg-yanisallali-magenta font-bold rounded-3xl px-5 py-2 focus-visible:outline-yanisallali-white transition-all hover:border-yanisallali-1/3-white active:bg-yanisallali-white active:text-yanisallali-magenta border-4 border-transparent",
                        className
                    )}
                    onMouseDown={() => {
                        setActive(true);
                    }}
                    onMouseUp={() => {
                        setActive(false);
                    }}
                >
                    <span>{label}</span>
                    <ReactSVG
                        src={iconPath} // Path to the SVG
                        className={clsx(
                            "w-[1rem] ml-2 [&_path]:transition-all",
                            iconClassName,
                            active && "[&_path]:stroke-yanisallali-magenta"
                        )}
                        alt="icon"
                    />
                </button>

                :
                <Link
                    to={url}
                    className={clsx(
                        "flex items-center justify-center bg-yanisallali-magenta font-bold rounded-3xl px-5 py-2 focus-visible:outline-yanisallali-white transition-all hover:border-yanisallali-1/3-white active:bg-yanisallali-white active:text-yanisallali-magenta border-4 border-transparent",
                        className
                    )}
                    onMouseDown={() => {
                        setActive(true);
                    }}
                    onMouseUp={() => {
                        setActive(false);
                    }}
                >
                    <span>{label}</span>
                    <ReactSVG
                        src={iconPath} // Path to the SVG
                        className={clsx(
                            "w-[1rem] ml-2 [&_path]:transition-all",
                            iconClassName,
                            active && "[&_path]:stroke-yanisallali-magenta"
                        )}
                        alt="icon"
                    />
                </Link>
            }
        </>
    );
}

export default CtaButton;

import { useEffect, useRef } from "react";
import ContactSection from "./ContactSection";
import EndSection from "./EndSection";
import LandingSection from "./LandingSection";
import Portfolio from "../Portfolio";
import { useLenis } from "lenis/react";
import { useLocation } from "react-router-dom";

function Temporary({isMobile, api, setLoading}) {
    const location = useLocation();
    const lenisRef = useRef();
    useLenis((lenis)=>{
        lenisRef.current = lenis;
    },[location]);

    useEffect(()=>{
        if(lenisRef.current && location.hash){
            setTimeout(()=>{
                lenisRef.current.scrollTo(document.querySelector(location.hash));
            },100);
        }
    },[location]);
    return (
        <div className="mt-8 tablet:mt-16 computer:mt-0">
            <LandingSection />
            <Portfolio setLoading={setLoading}/>
            <ContactSection api={api} />
        </div>
    )
}

export default Temporary;
import { useLenis } from "lenis/react";
import CtaButton from "../../../buttons/CtaButton";
import DecorativeButton from "../../../buttons/DecorativeButton";
import Heading from "../../../utils/texts/Heading";
import ArrowScrollAnimation from "./ArrowScrollAnimation";
import { useRef } from "react";

function LandingSection(props) {
    const lenisRef = useRef();
    useLenis((lenis)=>{
        lenisRef.current = lenis;
    });

    return (
        <div className="flex flex-col items-center large-computer:items-start computer:flex-row max-w-[1920px] mx-auto relative z-10 mb-16">
            <div className="flex flex-col items-center text-center computer:text-left computer:block large-computer:ml-32 computer:ml-8 computer:mt-16 max-w-[800px]">
                <div className="font-bold text-large tablet:text-larger mb-6">Salut ! 👋</div>
                <div className="mb-6 w-fit text-pretty">
                    <Heading
                        content={[
                            {
                                text: "Moi c'est ",
                            },
                            {
                                text: "Yanis Allali",
                                highlight: true,
                                break: true
                            },
                            {
                                text: " et voici mon ",
                            },
                            {
                                text: "portfolio",
                                highlight: true
                            },
                            {
                                text: " !"
                            }
                        ]}
                        fontSize={"text-thick tablet:text-extra-large"} />
                </div>
                <p className="text-semi-more tablet:text-large mb-8 text-pretty max-w-[425px]">Explorez une sélection de mes projets les plus marquants ✨</p>
                <div className="flex flex-col items-center gap-5 tablet:flex-row">
                    <CtaButton
                    label={"Voir le Portfolio"}
                    className={"w-full whitespace-nowrap computer:w-fit"}
                    iconPath={"/images/icons/Layers.svg"}
                    onClick={()=>{
                        if(lenisRef.current){
                            lenisRef.current.scrollTo(document.getElementById("portfolio"));
                        }
                    }}
                    />
                    <CtaButton
                    label={"Me Contacter"}
                    className={"computer:w-fit w-full !bg-transparent active:!bg-yanisallali-white  border-yanisallali-white !border-2 transition-all whitespace-nowrap"}
                    iconPath={"/images/icons/ContactIcon.svg"}
                    onClick={()=>{
                        if(lenisRef.current){
                            lenisRef.current.scrollTo(document.getElementById("contact"));
                        }
                    }}
                    />
                </div>
            </div>
            <img src="/images/Photo_Yans.png" className="absolute top-0 right-0 max-w-[600px] large-computer:max-w-[800px] hidden computer:block grayscale object-contain object-top pointer-events-none -z-10" />
        </div>
    )
}

export default LandingSection;
import { useEffect, useState } from "react";
import Heading from "../../utils/texts/Heading";
import ArrowScrollAnimation from "../Temporary/LandingSection/ArrowScrollAnimation";
import axios from "axios";
import { BASE_URL } from "../../../settings";
import PortfolioCategories from "./PortfolioCategories";
import PortfolioGrid from "./PortfolioGrid";
import ContactSection from "../Temporary/ContactSection";
import { ClipLoader } from "react-spinners";
import clsx from "clsx";
import { tr } from "date-fns/locale";
import { useLocation } from "react-router-dom";
import CtaButton from "../../buttons/CtaButton";
export default function Portfolio({setLoading}) {
    const location = useLocation();
    const [categories,setCategories] = useState([]);
    const [items,setItems] = useState([]);
    const [category,setCategory] = useState((location.state && location.state.category) ? location.state.category : null);
    const [itemsLoading,setItemsLoading] = useState(true);
    const [pageLoading,setPageLoading] = useState(false);
    const [page,setPage] = useState(1);
    const [nextPageAvailable,setNextPageAvailable] = useState(true);

    async function getCategories(){
        try{
            const response = await axios.get(`${BASE_URL}/categories_realisation`);
            setCategories(response.data);
            setLoading(false);
        } catch(err){
            console.log(err);
        }
    }

    async function getItems(reset = false){
        const perPage = 9;
        const url = `${BASE_URL}/realisations?per_page=${perPage}&page=${reset ? 1 : page}${category ? "&categories_realisation=" + category.id : ""}`;
        try{
            const response = await axios.get(url);
            setNextPageAvailable(response.data.length >= perPage);
            setItems(!reset ? [...items,...response.data] : response.data);
        } catch(err){
            console.log(err);
        } finally{
            setItemsLoading(false);
            setPageLoading(false);
        }
    }

    useEffect(()=>{
        getCategories();
    },[]);

    useEffect(()=>{
        if(page>1){
            setPageLoading(true);
            getItems();
        }
    },[page]);

    useEffect(()=>{
        setPage(1);
        setItemsLoading(true);
        getItems(true);
    },[category]);
    

    return (
        <div className="pt-48 relative z-20" id="portfolio">
            <div>
                <div className="flex flex-col items-center mb-8">
                    <h2 className="font-bold text-thick large-computer:text-larger">Triez par catégorie</h2>
                    <ArrowScrollAnimation />
                </div>
                <PortfolioCategories categories={categories} setCategory={setCategory} setAll={()=>{
                    setCategory(null);
                }} category={category}/>
            </div>
            <div className={clsx(
                "relative",
                itemsLoading ? "mt-12" : "mt-24"
            )}>
                {itemsLoading ? <ClipLoader color="#FFFFFF" className="absolute top-0 left-1/2 -translate-x-1/2"/> : <PortfolioGrid items={items} morePage={nextPageAvailable}/>}
            </div>
            {pageLoading && <div className="mt-16 flex justify-center items-center">
                <ClipLoader color="#FFFFFF"/>
            </div>}
            {(nextPageAvailable && !itemsLoading && !pageLoading) && <CtaButton label={"En voir plus"} iconPath={"/images/icons/Chevron-down.svg"} className={"w-fit mx-auto mt-14"} onClick={()=>{
                setPage(page + 1);
            }}/>}
        </div>
    )
}
import './App.css';
import CustomNavBar from './components/header/CustomNavBar';
import Temporary from './components/sections/Temporary';
import { useMediaQuery } from 'react-responsive';
import Credits from './components/footer/Credits';
import { Route, Routes, useLocation } from 'react-router-dom';
import NotFound from './components/sections/404/NotFound';
import Project from './components/sections/Project';
import { useEffect, useRef, useState } from 'react';
import Loader from './components/Loader';
import clsx from 'clsx';
import { match } from 'path-to-regexp';
import ReactLenis, { useLenis } from 'lenis/react';

function findRoute(routes = [], path = "") {
  return routes.find((v) => {
    const matcher = match(v.path, { decode: decodeURIComponent });
    return matcher(path) !== false;
  });
}


function App() {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)"
  });

  const location = useLocation();

  const [currentUrl, setCurrentUrl] = useState(location.pathname);

  const [loading, setLoading] = useState(true);

  const [loader, setLoader] = useState(true);

  const [loadingAnimation, setLoadingAnimation] = useState(true);

  const [route, setRoute] = useState(null);

  const lenisRef = useRef();

  useLenis((lenis)=>{
    lenisRef.current = lenis;
  });

  useEffect(() => {
    setLoading(true);
  }, [currentUrl]);

  useEffect(() => {
    if (!loadingAnimation) {
      const route = findRoute(routes, location.pathname);
      setRoute(route ? route : findRoute(routes, "*"));
    }
  }, [loadingAnimation]);

  useEffect(() => {
    if (loading) {
      setLoadingAnimation(true);
      setLoader(true);
    } else {
      handleResize();
    }
  }, [loading]);

  useEffect(() => {
    if (location.pathname != currentUrl) {
      setCurrentUrl(location.pathname);
    } else {
      if(lenisRef.current){
        lenisRef.current.scrollTo(0);
      }
    }
  }, [location]);

  useEffect(()=>{
    window.addEventListener("resize",handleResize);
    return ()=>{
      window.removeEventListener("resize",handleResize);
    }
  },[]);

  function handleResize(){
    document.body.style.minHeight = currentUrl.includes("projets") ? `${document.getElementById("project").offsetHeight}px` : "";
  }

  const routes = [
    {
      "path": "/",
      "element": <Temporary
        isMobile={isMobile} setLoading={setLoading}/>
    },
    {
      "path": "/portfolio/projets/:slug",
      "element": <Project setLoading={setLoading} />
    },
    {
      "path": "*",
      "element": <NotFound setLoading={setLoading} />
    }
  ]



  return (
    <ReactLenis root>
      <div className='bg-yanisallali-black min-w-screen min-h-dvh'>
        {loader && <Loader loading={loading} setLoader={setLoader} loadingAnimation={loadingAnimation} setLoadingAnimation={setLoadingAnimation} />}
        <div className={clsx(
          'bg-yanisallali-black min-w-screen min-h-dvh text-yanisallali-white font-gilroy relative flex flex-col',
          !route && "hidden"
        )}>
          <div className={clsx(
            'px-6 grow',
          )}>
            <header className='py-10 sticky top-0 left-0 w-full z-40 tablet:px-8'>
              <CustomNavBar />
            </header>
            <main>
              <Routes>
                {route && routes.map(
                  v => <Route path={v.path} element={route.element} />
                )}
              </Routes>
            </main>
          </div>
          <footer className={clsx(
            'mt-48',
            !route && "hidden"
          )}>
            <Credits />
          </footer>
        </div>
      </div>
    </ReactLenis>
  );
}

export default App;

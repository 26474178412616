import clsx from "clsx";
import PortfolioGridItem from "./PortfolioGridItem";

export default function PortfolioGrid({items, className, morePage, ...props}){
    return (
        <div className={clsx(
            "max-w-[1300px] mx-auto grid grid-cols-1 tablet:grid-cols-2 computer:grid-cols-3 gap-x-6 gap-y-8 computer:gap-y-0 relative",
            className
        )}>
            {items.map(
                (v,index)=><PortfolioGridItem key={index} {...v}/>
            )}
            {morePage && <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-yanisallali-black via-yanisallali-black/90 to-transparent pointer-events-none"/>}
        </div>
    )
}